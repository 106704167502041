@import "variables";
@import "fonts";

html {
  position: relative;
  width: 100%;
}

.main-artical {
  overflow-x: hidden;
}

body {
  position: relative !important;
  font-family: var(--regular);
  font-style: normal;
  font-size: 15px;
  width: 100%;
  max-height: 100%;
  min-height: 650px;
  overflow-x: hidden;
  background: url(../images/png/bg.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

img {
  max-width: 100%;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  cursor: pointer;
}

a,
.btn {
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

a:focus,
.btn:focus {
  text-decoration: none;
  outline: none;
}

p {
  // font-size: 1px;
  font-family: var(--regular);
  // padding-bottom: 15px;
}

*::-moz-selection {
  background: var(--primary);
  color: #fff;
  text-shadow: none;
}

*::-moz-selection {
  background: var(--primary) none repeat scroll 0 0;
  color: #fff;
  text-shadow: none;
}

::selection {
  background: var(--primary);
  color: #fff;
  text-shadow: none;
}

.bgGradient {
  background: var(--bgGradient);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.spacing {
  margin: 30px 0;
  padding: 50px 0;
}

.cursor-pointer {
  cursor: pointer;
}


/******************* admin css  *******************/
section {
  padding: 15px 20px;
}

.input-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 85%;
  max-height: 100vh;
  min-height: 400px;
  justify-content: center;
  margin-top: 15px;
}

.input-content-wrap {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 8px;
  margin: 10px 0;
  color: var(--darkBlue);
}

.input-field {
  padding: 4px 12px;
  border: 1px solid var(--white);
  background: var(--white);
  border: none;
  border-radius: 8px;
  height: 43px;
  width: 100%;
  outline: none;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  &::placeholder-shown {
    color: var(--gray);
  }
}

textarea {
  height: auto !important;
}

.common-main-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.addon-btn {
    padding: 4px 10px;
    border: 1px solid var(--darkBlue);
    border-radius: 8px;
    background: transparent;
    cursor: pointer;
}

.icon-remove {
  position: absolute;
    top: 0;
    right: 0;
    background-color: var(--red);
    border: var(--red);
    color: var(--white);
    transition: all ease-in-out .4s;
    // height: 43px;

  &:hover {
    background: rgba(255, 0, 0, 0.562);
  }
}
.img-view {
  width: 75px;
}



/********************sidebar css *******************/

.cross {
  display: none;
}

.menuClosed .cross {
  display: block;
}

.menuClosed .icon-trigger {
  display: none;
}

.sidebar.menuClosed {
  width: 67px;
  min-width: 67px;
  overflow: hidden;
}

.sidebar.menuClosed {
  position: fixed;
  top: 0px;
  left: 0;
  bottom: 0;
  padding-right: 10px;
  padding-left: 10px;
}

.sidebar.menuClosed .sidebar-list a span.menu-name {
  opacity: 0;
  visibility: hidden;
}

.sidebar.menuClosed .logoSidebar {
  display: none;
}

body.bodyClosed .topBar,
body.bodyClosed .secondaryBar {
  padding-left: 87px;
}

body.bodyClosed .main-content-container {
  padding-left: 67px;
}

body.bodyClosed .sidebar-list {
  padding: 0px 0px 0px 0px;
}

body.bodyClosed a {
  padding: 8px 12px;
}

/********************table css *******************/

.TableColumnWidth {
  width: auto;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

/********************Form CSS *******************/

.multiselect-field {
  border: 1px solid var(--white);
  background: var(--white);
  border: none;
  border-radius: 8px;
  height: 43px;
  width: 100%;
  outline: none;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  &::placeholder-shown {
    color: var(--gray);
  }
}

.multi-select-container {
  width: 100%;
}
.rmsc .dropdown-container {
  width: 100%!important;
  height: 100%!important;
  background-color: transparent!important;
  border: none!important;
}
.rmsc .dropdown-heading {
  height: 100%!important;
}
.rmsc .dropdown-container:focus-within {
  box-shadow: none!important;
  border: none!important;
}

.selected-pills {
  display: flex;
}

.pill {
  display: flex;
  align-items: center;
  background-color: #e0e0e0;
  border-radius: 15px;
  padding: 5px 10px;
  margin: 5px;
  font-size: 14px;
}

.pill-remove {
  margin-left: 10px;
  cursor: pointer;
  font-weight: bold;
  color: #000;
}
