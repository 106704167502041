:root {
    --primary: #E47CE0;
    --secondary: #5250ED;
    --purple: #A168E6;
    --bluePurple: #9E50ED;
    --darkBlue: #092064;
    --white: #ffffff;
    --bgGradient: linear-gradient(120deg, #E47CE0 0%, #5451ED 100%);
    --bgField: linear-gradient(60deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    --green2: #0fbc00;
    --red: #e8374f;
    --gray: #92929d;

}